"use client";
import React from "react";
import {
  Disclosure,
  Transition,
  DisclosurePanel,
  DisclosureButton,
} from "@headlessui/react";
import Link from "next/link";

export function PopupWidget() {
  return (
    <div>
      <Disclosure>
        {({ open }) => (
          <>
            <DisclosureButton className="fixed z-40 flex items-center justify-center transition duration-300 bg-indigo-500 rounded-full shadow-lg right-5 bottom-5 w-14 h-14 focus:outline-none hover:bg-indigo-600 focus:bg-indigo-600 ease">
              <span className="sr-only">Open Contact form Widget</span>
              <Transition
                show={!open}
                enter="transition duration-200 transform ease"
                enterFrom="opacity-0 -rotate-45 scale-75"
                leave="transition duration-100 transform ease"
                leaveTo="opacity-0 -rotate-45"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="absolute w-6 h-6 text-white"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                </svg>
              </Transition>

              <Transition
                show={open}
                enter="transition duration-200 transform ease"
                enterFrom="opacity-0 rotate-45 scale-75"
                leave="transition duration-100 transform ease"
                leaveTo="opacity-0 rotate-45"
                className="absolute w-6 h-6 text-white"
                as={"div"}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </Transition>
            </DisclosureButton>
            <Transition
              className="fixed  z-50 bottom-[100px] top-0 right-0  left-0 sm:top-auto sm:right-5 sm:left-auto"
              enter="transition duration-200 transform ease"
              enterFrom="opacity-0 translate-y-5"
              leave="transition duration-200 transform ease"
              leaveTo="opacity-0 translate-y-5"
              as="div"
            >
              <DisclosurePanel className=" flex flex-col  overflow-hidden left-0  h-max w-full sm:w-[350px] min-h-[100px] sm:h-[600px] border border-gray-300 dark:border-gray-800 bg-white shadow-2xl rounded-md sm:max-h-[calc(100vh-120px)]">
                <div className="p-5 grid grid-cols-1 gap-5">
                  <Link
                    href="https://wa.link/as67oh"
                    className="w-full px-3 py-4 text-white bg-green-500 rounded-md focus:bg-green-600 focus:outline-none"
                    target="_blank"
                  >
                    Contact Whatsapp
                  </Link>
                  <Link
                    href="https://www.instagram.com/sahabad_solution/?igshid=MzNlNGNkZWQ4Mg%3D%3D"
                    className="w-full px-3 py-4 text-white bg-gradient-to-r from-pink-500 to-ungu-400 rounded-md focus:bg-indigo-600 focus:outline-none"
                    target="_blank"
                  >
                    Contact Instagram
                  </Link>
                  <Link
                    href="https://www.facebook.com/profile.php?id=100095380622749&mibextid=9R9pXO"
                    className="w-full px-3 py-4 text-white bg-blue-500 rounded-md focus:bg-indigo-600 focus:outline-none"
                    target="_blank"
                  >
                    Contact Facebook
                  </Link>
                  <Link
                    href="https://www.google.com/maps/dir//Jl.+Bintara+XIV+No.30,+RT.003%2FRW.004,+Bintara,+Kec.+Bekasi+Bar.,+Kota+Bks,+Jawa+Barat+17134/@-6.2285294,106.8942487,12z/data=!3m1!4b1!4m8!4m7!1m0!1m5!1m1!1s0x2e698d3aca814335:0x7d6b8bdd0a5a7416!2m2!1d106.9642893!2d-6.228534?entry=ttu"
                    className="w-full px-3 py-4 text-white bg-indigo-500 rounded-md focus:bg-indigo-600 focus:outline-none"
                    target="_blank"
                  >
                    Sahabad Solution Maps
                  </Link>
                </div>
              </DisclosurePanel>
            </Transition>
          </>
        )}
      </Disclosure>
    </div>
  );
}
